(function ($) {
	"use strict";
	
	$(window).load(function () {
		var testimonial = $(".testimonial-slider-");
		var testimonial_left = $(".testimonial-slider-left");
		
		if ( testimonial.length ) {
			testimonial.owlCarousel({
				singleItem : true,
				stopOnHover: true,
				pagination : true,
				mouseDrag  : true,
				touchDrag  : false,
				transitionStyle : "backSlide"
			});
		}
		
		if ( testimonial_left.length ) {
			testimonial_left.owlCarousel({
				singleItem : true,
				stopOnHover: true,
				pagination : true,
				mouseDrag  : true,
				touchDrag  : false,
				transitionStyle : "goDown"
			});
		}

	});
	
})(jQuery);

